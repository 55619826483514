<script setup>
import { onMounted, computed, ref } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';
import { getActiveLanguage } from 'laravel-vue-i18n';
import Modal from '@/Components/Modal.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';

const page = usePage();

const currentLanguage = ref(getActiveLanguage());

const form = useForm({
    teacher: false
});

const user = ref(page.props.auth.user);

const submit = async () => {
    form.patch(route(`role.update.${currentLanguage.value}`), {
        onFinish: () => {
            form.reset();
            user.value.onboarded = true;
        },
    });
};

// const prevent = (e) => {
//     e.preventDefault();
//     return false;
// };

</script>
<template>
    <Modal v-if="user" :show="!user.onboarded">
        <form @submit.prevent="submit">
            <div class="p-2 md:p-6">
                <div>
                    <div class="text-2xl p-2 text-gray-700 font-semibold">{{ $t('auth.onboarding_teacher_title', {
                        'first_name': user.first_name,
                    }) }}</div>
                </div>
                <div class="mt-3 mb-3 flex flex-col gap-6">
                    <div class="p-2 pb-4">
                        <div class="text-gray-500">{{ $t('auth.onboarding_teacher_question') }}</div>
                    </div>
                    
                </div>
                <div>
                    <div class="flex gap-4 justify-end mt-6">
                        <SecondaryButton @click="() => { form.teacher = false; submit(); }">{{ $t('auth.onboarding_not_teacher') }}</SecondaryButton>
                        <PrimaryButton @click="() => { form.teacher = true }">{{ $t('auth.onboarding_is_teacher') }}</PrimaryButton>
                    </div>
                </div>
            </div>
        </form>
    </Modal>
</template>